import React from 'react';
import FormBlock from './FormBlock';
import OtherContact from './OtherContact';

const Contact = () => {
  return (
    <main className="main">
      <section className="opening opening-healthcare opening-pages">
        <div className="opening-pages-box">
          <div className="container">
            <div className="opening__inner" style={{ paddingBottom: '50px', paddingTop: '50px' }}>
              <div className="opening__info">
                <h1 className="opening__title">Let’s Talk!</h1>
                <div className="subtitle-block">
                  Please contact us today if you need more information about our services or general
                  information. We look forward to talking to you!
                </div>
              </div>
              <div className="opening__img">
                <img src="./images/price.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <FormBlock />
      <OtherContact />
    </main>
  );
};

export default Contact;
