import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  array: [],
};

export const PriceSlice = createSlice({
  name: 'price',
  initialState,
  reducers: {
    setArray: (state, actions) => {
      state.array = actions.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setArray } = PriceSlice.actions;

export default PriceSlice.reducer;
