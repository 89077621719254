import React from 'react';

import Lightbox from 'yet-another-react-lightbox';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import placeHolder from '../../assets/images/placeholder.webp';

import { useCallback } from 'react';

const CoolLightbox = ({ images, isOpen, onClose }) => {
  return (
    <Lightbox
      open={isOpen}
      close={onClose}
      slides={images}
      controller={{ touchAction: 'pan-y', closeOnBackdropClick: true }}
      styles={{ root: { '--yarl__color_backdrop': 'rgba(0, 0, 0, .8)' } }}
    />
  );
};

const PortfolioItem = ({ title, items, folder, url, id }) => {
  const [lightboxImages, setImg] = React.useState([]);
  const [isOpen, setOpen] = React.useState(false);

  const Open = useCallback(() => {
    setOpen(true);
  }, []);

  React.useEffect(() => {
    setImg(
      items.img.map((e) => {
        return { src: `../images/portfolio/${folder}${url}/${id}/${e}`, loading: 'lazy' };
      }),
    );
  }, [items]);

  return (
    <div className="blog-box">
      <a onClick={Open} className="blog-box__photo">
        <i className="icon_counter">
          <span>{items.img.length}</span>
        </i>

        <LazyLoadImage
          alt={`${title} Case #${id}`}
          wrapperClassName="placeholder-cover"
          placeholderSrc={placeHolder}
          src={`../images/portfolio/${folder}${url}/${id}/${items.img[0]}`}
        />

        <CoolLightbox isOpen={isOpen} onClose={() => setOpen(!isOpen)} images={lightboxImages} />
      </a>

      <div className="blog-box__info">
        <div className="blog-box__category blog-category">{title}</div>
        <div className="blog-box__title">
          <a href="#">Case #{id}</a>
        </div>
        <div className="blog-box__text"></div>
      </div>
    </div>
  );
};

export default PortfolioItem;
