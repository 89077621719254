import React from 'react';
import PortfolioItem from './PortfolioItem';
import { nanoid } from 'nanoid';

const PortfolioBlock = ({ value, folder }) => {
  return (
    <div className="portfolio_item" id={value.url}>
      <div className="advantages-box__info new">
        <div className="box-title-border">
          <div className="advantages-box__title">{value.title}</div>
          <div className="advantages-box__text">{value.description}</div>
        </div>
      </div>

      <div className="blog-list__inner">
        {value.items.map((e, index) => {
          return (
            <PortfolioItem
              title={value.title}
              items={e}
              folder={folder}
              url={`${value.url ? `/${value.url}` : ''}`}
              id={index + 1}
              key={nanoid()}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PortfolioBlock;
