import React from 'react';

const Error = () => {
  return (
    <main className="main">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p></p>
    </main>
  );
};

export default Error;
