import React from 'react';
import { Link } from 'react-router-dom';
import { nanoid } from 'nanoid';

const Item = ({ e, setTab }) => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    const hundlerCheck = (ev) => {
      if (ev.composedPath().includes(ref.current)) {
        return setTab(false);
      }
    };

    document.addEventListener('click', hundlerCheck);

    return () => {
      document.removeEventListener('click', hundlerCheck);
    };
  }, []);

  return (
    <Link key={nanoid()} to={e.url} className="header-menu__item purple" ref={ref}>
      <div className="header-menu__icon">
        <img src={e.img} alt=""></img>
      </div>
      <div className="header-menu__info">
        <div className="header-menu__name">{e.title}</div>
        <div className="header-menu__text">{e.desc}</div>
      </div>
    </Link>
  );
};

const NavItem = ({ title, url, sub }) => {
  const [tab, setTab] = React.useState(false);
  const menuRef = React.useRef(null);

  React.useEffect(() => {
    if (sub) {
      const hundlerCheck = (ev) => {
        if (!ev.composedPath().includes(menuRef.current)) {
          return setTab(false);
        }
      };

      document.addEventListener('click', hundlerCheck);

      return () => {
        document.removeEventListener('click', hundlerCheck);
      };
    }
  }, []);

  if (!sub) {
    return (
      <Link to={url} className="header-menu__link">
        <span>{title}</span>
      </Link>
    );
  }

  return (
    <div className="header-menu__link header-menu__drop" ref={menuRef} onClick={() => setTab(true)}>
      <span>{title}</span>

      <div className={`header-menu__dropdown ${tab ? 'active' : ''}`}>
        <div className="container">
          <div className="header-menu__list header-menu__list_full2">
            <div className="header-menu__title">
              <i>{title}</i>
            </div>
            <div className="header-menu__link-block header-menu__link-block_full2">
              {sub.map((e) => {
                return <Item key={nanoid()} e={e} setTab={setTab} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavItem;
