import React from 'react';
import { nanoid } from 'nanoid';
import { Link } from 'react-router-dom';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import casePlace from '../../assets/images/case_place.svg';

const Case = ({ data }) => {
  const [tab, setTab] = React.useState(0);

  return (
    <div className={`customer-box ${data.styleBlock}`}>
      <div className="customer__header">
        <h3 className="customer-box__title">
          <img src={`./images/${data.icon}`} alt="" />
          <span>{data.name}</span>
        </h3>

        <div className="customer__tabs-nav tabs-nav">
          {data.tabs.map((e, index) => {
            return (
              <a
                onClick={() => setTab(index)}
                key={nanoid()}
                className={tab === index ? `t${index} active` : `t${index}`}>
                {e.title}
              </a>
            );
          })}
        </div>
      </div>

      {data.tabs.map((e, index) => {
        return (
          <div
            key={nanoid()}
            className={`customer__tab tab-block tab_${index} ${tab == index ? ' active' : ''}`}>
            <div className="customer__inner">
              <div className="customer__info">
                <div className="customer__title">{e.title}</div>
                <div className="customer__subtitle">{e.description}</div>
                <div className="button-cases">
                  <Link
                    to={`/portfolio/${data.url}${e.link ? '#' + e.link : ''}`}
                    className="customer__btn">
                    Our Сases
                  </Link>
                  <Link to={`/price#${data.hash}`} className="customer__btn">
                    Price
                  </Link>
                </div>
              </div>
              <div className="customer__content">
                <div className="customer__content-box">
                  <LazyLoadImage
                    src={`./images/service/${e.img}`}
                    placeholderSrc={casePlace}
                    alt={e.title}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Case;
