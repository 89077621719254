import React from 'react';
import PriceItem from './PriceItem';
import { nanoid } from 'nanoid';
import { Link } from 'react-router-dom';

const configPrice = (str, price, price2, symbol) => {
  return str.replace('{p}', price).replaceAll('{s}', symbol).replace('{p2}', price2);
};
const PriceBox = ({ value, icon }) => {
  console.log(value);
  return (
    <section className="price" id={value.name.toLowerCase()}>
      <div className="container">
        <div className="title__price__block">
          <h2 className="title-block left">{value.title}</h2>

          <Link class="price__btn" to={`/portfolio/${value.name}`}>
            Our Сases
          </Link>
        </div>
        <div className="price__box">
          {value.service.map((e) => {
            return (
              <PriceItem
                key={nanoid()}
                icon={icon}
                title={e.title}
                priceText={
                  !e.textPrice
                    ? `${e.symbol}${e.price}`
                    : configPrice(e.textPrice, e.price, e.priceEach, e.symbol)
                }
                description={e.description}
                pricePrefix={e.per && e.per}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default PriceBox;
