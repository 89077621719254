import React from 'react';

import step1 from '../../assets/images/step1.svg';
import step2 from '../../assets/images/step3.svg';
import step3 from '../../assets/images/step4.svg';
import step4 from '../../assets/images/step6.svg';

const Steps = () => {
  return (
    <div className="preference">
      <div className="container">
        <div className="preference__inner">
          <div className="preference__item">
            <div className="preference__icon">
              <img src={step1} alt="" />
            </div>
            <div className="preference__text">STL-Scans</div>
          </div>

          <div className="preference__item">
            <div className="preference__icon">
              <img src={step2} alt="" />
            </div>
            <div className="preference__text">Order Form</div>
          </div>

          <div className="preference__item">
            <div className="preference__icon">
              <img src={step3} alt="" />
            </div>
            <div className="preference__text">Design</div>
          </div>

          <div className="preference__item">
            <div className="preference__icon">
              <img src={step4} alt="" />
            </div>
            <div className="preference__text">Manufacturing in Client Lab/Clinic</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Steps;
