import React from 'react';
import { useForm } from 'react-hook-form';

import { codeCountry } from '../../data/countryCode';

const FormBlock = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => console.log(data);

  const [phoneN, setPhoneN] = React.useState('');

  React.useEffect(() => {
    const d = phoneN.substring(0, 3);
    console.log(
      codeCountry.find((e) => {
        return e.dial_code == d;
      }),
    );
  }, [phoneN]);

  return (
    <section class="contact-us">
      <div class="container">
        <div class="contact-us__box">
          <h2 class="title-block title-block_center">Contact Form</h2>
          <form onSubmit={handleSubmit(onSubmit)} class="contact-us-form">
            <div class="contact-us-form__inner__box">
              <div class="contact-us-form__inner">
                <div class="contact-us-form__item">
                  <label htmlFor="contact-us-name">Name *</label>
                  <input
                    {...register('name', { required: true, minLength: 1 })}
                    id="contact-us-name"
                    placeholder="Your Name"
                  />
                </div>

                <div class="contact-us-form__item">
                  <label htmlFor="contact-us-phone">Phone</label>
                  <input
                    {...register('phone', {})}
                    id="contact-us-phone"
                    placeholder="Phone"
                    onChange={(e) => setPhoneN(e.target.value)}
                  />
                </div>

                <div class="contact-us-form__item">
                  <label htmlFor="contact-us-subject">Subject</label>
                  <input
                    {...register('subject', {})}
                    id="contact-us-subject"
                    placeholder="Subject"
                  />
                </div>
              </div>

              <div class="contact-us-form__item msg">
                <label htmlFor="contact-us-message">Message</label>
                <textarea
                  {...register('message', { minLength: 5 })}
                  id="contact-us-message"
                  placeholder="Enter your question"
                />
              </div>
            </div>
            <div class="contact-us-form__foot">
              <button class="btn-gradient" type="submit">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default FormBlock;
