import React from 'react';
import { nanoid } from 'nanoid';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setStatus } from '../../store/slice/MenuSlice';

import { menuItem } from '../../data/NavigationData';

const DropDown = ({ title, sub }) => {
  const [open, setOpen] = React.useState(false);

  const { pathname } = useLocation();

  React.useEffect(() => {
    setOpen(false);
  }, [pathname]);

  return (
    <li>
      <a className={`parent dropdown ${open ? 'active' : ''}`} onClick={() => setOpen(!open)}>
        {title}
      </a>
      {open && (
        <div style={{ display: 'block' }}>
          <ol>
            {sub.map((e) => {
              return (
                <li key={nanoid()}>
                  <NavLink to={e.url}>{e.title}</NavLink>
                </li>
              );
            })}
          </ol>
        </div>
      )}
    </li>
  );
};

const Item = ({ title, url, sub }) => {
  if (!sub) {
    return (
      <li>
        <NavLink className="parent" to={url}>
          {title}
        </NavLink>
      </li>
    );
  }

  return <DropDown title={title} sub={sub} />;
};

const Burger = () => {
  const dispatch = useDispatch();
  const { active } = useSelector((state) => state.menu);
  const { pathname } = useLocation();

  React.useEffect(() => {
    active && dispatch(setStatus(false));
  }, [pathname]);

  return (
    <>
      <div className={`window-menu ${active ? 'show' : ''}`}>
        <div className="window-content">
          <div className="closex action-window" onClick={() => dispatch(setStatus(false))}></div>

          <div className="navigate">
            <ul>
              {menuItem.map((e) => {
                return <Item title={e.title} url={e.url} sub={e.sub} key={nanoid()} />;
              })}
            </ul>
          </div>
        </div>
      </div>

      <div
        className={`bg-popup ${active ? 'active' : ''}`}
        onClick={() => dispatch(setStatus(false))}></div>
    </>
  );
};

export default Burger;
