import React from 'react';

import price from '../../data/price';

import { useSelector, useDispatch } from 'react-redux';
import { setArray } from '../../store/slice/PriceSlice';
import { scrollToLocation } from '../../helper';
import { useLocation } from 'react-router-dom';

import icon1 from '../../assets/images/pen-icon.svg';
import icon2 from '../../assets/images/pen-icon_2.svg';
import icon3 from '../../assets/images/chart-bar-icon.svg';
import icon4 from '../../assets/images/chart-line-icon.svg';

import PriceBox from './PriceBox';

const Price = () => {
  const dispatch = useDispatch();
  const { hash } = useLocation();
  const { array } = useSelector((state) => state.price);

  const arrayIcon = [icon1, icon2, icon3, icon4];

  React.useEffect(() => {
    scrollToLocation();
  }, [hash]);

  React.useEffect(() => {
    dispatch(setArray(price));
  }, []);

  return (
    <main className="main page page_healthcare">
      <section className="opening opening-healthcare opening-pages">
        <div className="opening-pages-box">
          <div className="container">
            <div className="opening__inner" style={{ paddingBottom: '50px', paddingTop: '50px' }}>
              <div className="opening__info">
                <h1 className="opening__title">Base Price for Our Services</h1>
                <div className="subtitle-block">
                  If you are interested in other services, our managers will gladly prepare a
                  personal offer for you
                </div>
              </div>
              <div className="opening__img">
                <img src="./images/price.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {array.map((e, index) => {
        return <PriceBox value={e} icon={arrayIcon[index]} key={index} />;
      })}
    </main>
  );
};

export default Price;
