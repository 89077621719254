import React from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements,
} from 'react-router-dom';

import Layout from './components/Layout';

import Home from './components/Home';
import Portfolio from './components/Portfolio';
import Error from './components/Error';
import About from './components/About';
import Price from './components/Price';
import Test from './components/Test';
import Contact from './components/Contact';

import { getExchange } from './data/fetchData';
import Meta from './components/Meta';
import MetaData from './data/meta';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route
        index
        element={
          <Meta meta={MetaData.home}>
            <Home />
          </Meta>
        }
      />
      <Route
        path="about"
        element={
          <Meta meta={MetaData.about}>
            <About />
          </Meta>
        }
      />
      <Route
        path="portfolio/:name"
        element={
          <Meta meta={MetaData.portfolio}>
            <Portfolio />
          </Meta>
        }
      />
      <Route
        path="price"
        element={
          <Meta meta={MetaData.price}>
            <Price />
          </Meta>
        }
      />
      <Route
        path="contact"
        element={
          <Meta meta={MetaData.contact}>
            <Contact />
          </Meta>
        }
      />
      <Route path="*" element={<Error />} />
    </Route>,
  ),
);
const App = () => {
  return (
    <>
      <TawkMessengerReact propertyId="635440dab0d6371309caf204" widgetId="1gg0hqm18" />
      <RouterProvider router={router} />
    </>
  );
};

export default App;
