import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  active: false,
};

export const MenuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setStatus: (state, actions) => {
      state.active = actions.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setStatus } = MenuSlice.actions;

export default MenuSlice.reducer;
