export default [
  {
    id: 'cad',
    title: 'CAD-Design',
    description: '',
    url: 'cad',
    portfolio: [
      {
        title: 'Crowns & Bridges',
        description:
          'All types of frameCases and full -contour restorations like PFM, Zirconia, E.max.',
        url: 'crowns',
        items: [
          {
            img: ['1.webp', '2.webp', '3.webp'],
          },
          {
            img: ['1.webp', '2.webp', '4.webp', '5.webp'],
          },
          {
            img: [
              '1c7062babcfb7b946dd9e4e64599811c.webp',
              '4963eeb9297b895a87346c2c5c40541a.webp',
              'a21560f0746c2fa56fd86242e99f4ca4.webp',
            ],
          },
          {
            img: [
              '1.webp',
              '28864501deb7f0ca11de8d71ac0ee3f7.webp',
              '80e7b7bc8712079ca342270ef54c833c.webp',
              'd0d24ce3f48bb6daf44a1feffa4bf30e.webp',
              'ea9c8414d030714f44f537ed3bbf6fdb.webp',
            ],
          },
          {
            img: ['1.webp', '2.webp', '3.webp', '4.webp', '5.webp'],
          },
        ],
      },
      {
        title: 'Abutments',
        description:
          'All solution for You: from hybrid zirconia abutments to titanium for Pre-Face blanks. Predictable and strong',
        url: 'abutments',
        items: [
          {
            img: ['Screenshot_1.webp', 'Screenshot_2.webp'],
          },
          {
            img: ['1.webp', '2.webp'],
          },
          {
            img: ['1.webp', '2.webp'],
          },
        ],
      },
      {
        title: 'Bars',
        description:
          'Predictible design for bars in All on 4, all on 6 concepts, bars for removable dentures with CEKA, Bredent and another attachmens',
        url: 'bars',
        items: [
          {
            img: ['work_1.1.webp', 'work_1.2.webp'],
          },
          {
            img: ['work_3.1.webp', 'work_3.2.webp'],
          },
          {
            img: ['work_4.webp'],
          },
          {
            img: ['work_5.webp'],
          },
          {
            img: ['work_6.webp'],
          },
        ],
      },
      {
        title: 'Splints and Deprogramators',
        description: 'Sved-type splint, Flat plane splints, Repositioning Splint, Gelb Splint.',
        url: 'splints',
        items: [
          {
            img: ['2.webp', '3.webp'],
          },
          {
            img: ['1.webp', '2.webp', '3.webp'],
          },
        ],
      },
    ],
  },
  {
    id: 'surgical',
    title: 'Surgical Guides',
    description: '',
    url: 'surgical',
    portfolio: [
      {
        title: 'Mono Guide',
        description:
          "We provide planing of surgical guides based on patient's teeth. For pilot drilling and full drilling with implant placement through the surgical guide. For most than 50 implant systems",
        url: 'mono',
        items: [
          {
            img: ['1.webp'],
          },
          {
            img: ['2.webp'],
          },
          {
            img: ['3.1.webp', '3.2.webp'],
          },
          {
            img: ['4.webp'],
          },
          {
            img: ['5.webp'],
          },
        ],
      },
      {
        title: 'Double Guide',
        description:
          'All solution for You: from hybrid zirconia abutments to titanium for Pre-Face blanks. Predictable and strong',
        url: 'double',
        items: [
          {
            img: [
              '1.webp',
              'work_2.1.webp',
              'work_2.2.webp',
              'work_2.3.webp',
              'work_2.4.webp',
              'work_2.5.webp',
              'work_2.7.webp',
              'work_2.8.webp',
            ],
          },
          {
            img: ['1.webp', '2.webp'],
          },
        ],
      },
    ],
  },
  {
    id: 'orthodontics',
    title: 'Orthodontics',
    description: '',
    url: 'orthodontics',
    portfolio: [
      {
        title: 'Clear Aligners',
        description:
          'Designed by orthodontists, OUTCAD Aligners gives you complete control of your Aligners workflow. Package offer for Your practice',
        url: 'aligners',
        items: [
          {
            img: ['1.1.webp', '1.2.webp', '1.3.webp', '1.4.webp'],
          },
        ],
      },
      {
        title: 'Indirect Bonding Trays for Bracket Placement',
        description: 'Create for reducing chair time and increase patient comfort.',
        url: 'bracket',
        items: [
          {
            img: ['1.webp'],
          },
        ],
      },
    ],
  },
  {
    id: 'assistant',
    title: 'CAM-Assistant',
    description: '',
    url: 'assistant',
    portfolio: [
      {
        title: 'Software: Hyperdent and Millbox',
        description:
          'Your remote milling machine operator. A set of strategies developed by our engineers will bring your product to a qualitatively new level',
        url: null,
        items: [
          {
            img: ['1.webp'],
          },
          {
            img: ['2.webp'],
          },
        ],
      },
    ],
  },
];
