import React from 'react';

const Goal = () => {
  return (
    <section className="advantages">
      <div className="container">
        <h2 className="title-block title-block_center">Your Success is Our Goal!</h2>
        <div className="subtitle-block subtitle-block_center">Offer the best to your patients.</div>
        <div className="advantages__inner">
          <div className="advantages-box">
            <div className="advantages-box__icon shadow-pink">
              <img src="./images/advantages-icon_1.svg" alt="" />
            </div>
            <div className="advantages-box__info">
              <div className="advantages-box__title">Personal manager</div>
              <div className="advantages-box__text">
                It is important for us to hear all your wishes and fulfill our task exactly on time.
              </div>
            </div>
          </div>

          <div className="advantages-box">
            <div className="advantages-box__icon shadow-purple">
              <img src="./images/advantages-icon_2.svg" alt="" />
            </div>
            <div className="advantages-box__info">
              <div className="advantages-box__title">Independence</div>
              <div className="advantages-box__text">
                No more fear that you will be left in a losing situation when your employee quits.
                We are with you.
              </div>
            </div>
          </div>

          <div className="advantages-box">
            <div className="advantages-box__icon shadow-green">
              <img src="./images/advantages-icon_3.svg" alt="" />
            </div>
            <div className="advantages-box__info">
              <div className="advantages-box__title">Easy payment</div>
              <div className="advantages-box__text">
                For each case, monthly invoice, pre-payment. Official invoice or cryptocurrency.
              </div>
            </div>
          </div>

          <div className="advantages-box">
            <div className="advantages-box__icon shadow-blue">
              <img src="./images/advantages-icon_4.svg" alt="" />
            </div>
            <div className="advantages-box__info">
              <div className="advantages-box__title">Speed</div>
              <div className="advantages-box__text">
                From 15 minutes to 24 hours. We understand how important speed is in our
                cooperation.
              </div>
            </div>
          </div>

          <div className="advantages-box">
            <div className="advantages-box__icon shadow-black">
              <img src="./images/advantages-icon_5.svg" alt="" />
            </div>
            <div className="advantages-box__info">
              <div className="advantages-box__title">Experience</div>
              <div className="advantages-box__text">
                More than 30,000 patients with prostheses by our specialists. From simple overlays
                to total rehabilitation in ALL on X concepts.
              </div>
            </div>
          </div>

          <div className="advantages-box">
            <div className="advantages-box__icon shadow-yellow">
              <img src="./images/advantages-icon_6.svg" alt="" />
            </div>
            <div className="advantages-box__info">
              <div className="advantages-box__title">Economy</div>
              <div className="advantages-box__text">
                You will no longer have to buy expensive licenses for each software. We are your
                assistants in all areas of digital dentistry.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Goal;
