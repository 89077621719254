export default {
  home: {
    title: 'OutCad | Solution for Your Practice in Dental Design',
    description: '',
  },
  about: {
    title: 'About Us | OutCad',
    description: '',
  },
  portfolio: {
    title: '| OutCad',
    description: '',
  },
  price: {
    title: 'Base Price for Our Services | OutCad',
    description: '',
  },
  contact: {
    title: 'Let’s Talk! | OutCad',
    description: '',
  },
};
