import React from 'react';
import Slider from './Slider';
import Goal from './Goal';
import Steps from './Steps';
import Cases from './Cases';

const Home = () => {
  React.useEffect(() => {
    document.title = '12333';
  }, []);
  
  return (
    <main className="main">
      <Slider />
      <Cases />
      <Steps />
      <Goal />
    </main>
  );
};

export default Home;
