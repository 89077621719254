import React from 'react';
import { useLocation } from 'react-router-dom';

const Meta = ({ meta, children }) => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    document.title = meta.title;
  }, [pathname]);

  return <>{children}</>;
};

export default Meta;
