import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  array: [],
  value: {},
};

export const PortfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    setArray: (state, actions) => {
      state.array = actions.payload;
    },
    setValue: (state, actions) => {
      state.value = state.array.find((e) => {
        return e.id == actions.payload;
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const { setArray, setValue } = PortfolioSlice.actions;

export default PortfolioSlice.reducer;
