import Img1 from '../assets/images/pen-icon.svg';
import Img2 from '../assets/images/pen-icon_2.svg';
import Img3 from '../assets/images/chart-bar-icon.svg';
import Img4 from '../assets/images/chart-line-icon.svg';

export const menuItem = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'About Us',
    url: '/about',
  },
  {
    title: 'Product Portfolio',
    url: '',
    sub: [
      {
        title: 'CAD-Design',
        url: '/portfolio/cad',
        desc: 'Сrowns and frameworks made by our team.',
        img: Img1,
      },
      {
        title: 'Surgical Guides',
        url: '/portfolio/surgical',
        desc: 'For surgeons created by surgeons.',
        img: Img2,
      },
      {
        title: 'Orthodontics',
        url: '/portfolio/orthodontics',
        desc: 'Our solutions in Ortho branch.',
        img: Img3,
      },
      {
        title: 'CAM-Assistant',
        url: '/portfolio/assistant',
        desc: 'We calculate - You mill! The best tool paths for everyday use.',
        img: Img4,
      },
    ],
  },
  {
    title: 'Price',
    url: '/price',
  },
  {
    title: 'Contact Us',
    url: '/contact',
  },
];
