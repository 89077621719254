import React from 'react';

const PriceItem = ({ icon, title, priceText, pricePrefix = 'unit', description }) => {
  return (
    <div className="price__info">
      <div className="title-block-price">
        <img src={icon} alt="" />
        <div className="price__title min-100">{title}</div>
      </div>
      <div className="price__text">
        <p>
          Price per {pricePrefix}: <br />
          <span className="badge badge-primary">{priceText}</span>
        </p>
        {description && (
          <ul className="price__list">
            <li>{description}</li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default PriceItem;
