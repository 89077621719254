import React from 'react';

import { useDispatch } from 'react-redux';
import { setStatus } from '../../store/slice/MenuSlice';
import { nanoid } from 'nanoid';

import { menuItem } from '../../data/NavigationData';
import NavItem from './NavItem';

const Nav = () => {
  const dispatch = useDispatch();

  return (
    <>
      <nav className="header-menu">
        <div className="header-menu__links">
          {menuItem.map((e) => (
            <NavItem key={nanoid()} title={e.title} url={e.url} sub={e.sub} />
          ))}
        </div>
      </nav>

      <div
        className="burger__button"
        onClick={() => {
          dispatch(setStatus(true));
        }}></div>
    </>
  );
};

export default Nav;
