import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../assets/images/logo-wh.svg';
const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__menu">
        <div className="container">
          <div className="footer-menu">
            <div className="footer-menu__col">
              <Link to="/" className="footer-logo">
                <img src={Logo} alt="" />
              </Link>
              <div className="footer__description">
                OutCad - Solution for Your Practice in Dental Design
              </div>
              <div className="payments_text">We Accept:</div>
              <div className="payments">
                <div className="visa-pay tooltip">
                  <span className="tooltiptext">Tooltip text</span>
                </div>
                <div className="mc-pay"></div>
                <div className="wise-pay"></div>
                <div className="paypal-pay"></div>
                <div className="crypto-pay"></div>
              </div>
            </div>
            <div className="footer-menu__col">
              <div className="footer-menu__title">OutCad</div>
              <ul className="footer-menu__list">
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/price">Price</Link>
                </li>
                <li>
                  <Link to="/terms">Terms and Conditions</Link>
                </li>
              </ul>
            </div>
            <div className="footer-menu__col">
              <div className="footer-menu__title">Portfolio</div>
              <ul className="footer-menu__list">
                <li>
                  <Link to="/portfolio/cad-design">CAD-Design</Link>
                </li>
                <li>
                  <Link to="/portfolio/surgical">Surgical Guides</Link>
                </li>
                <li>
                  <Link to="/portfolio/orthodontics">Orthodontics</Link>
                </li>
                <li>
                  <Link to="/portfolio/cam-assistant">CAM-Assistant</Link>
                </li>
              </ul>
            </div>

            <div className="footer-menu__col">
              <div className="footer-menu__title">Contact Us</div>
              <ul className="footer-menu__list">
                <li>
                  <a href="https://wa.me/380934739258">WhatsApp Support</a>
                </li>
                <li>
                  <a href="https://m.me/107956448731025">Messenger Support</a>
                </li>
                <li>
                  <a href="viber://chat?number=%2B380934739258">Viber Support</a>
                </li>
                <li>
                  <a href="https://t.me/outcad">Telegram Support</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__foot">
        <div className="container">
          <div className="footer__row">
            <div className="copyright">© 2022 OUTCAD.NET. All rights reserved</div>
            <div className="contact_footer">
              <div>
                <a>E-Mail:</a>
                <a href="mailto:outcad24.7@gmail.com">outcad24.7@gmail.com</a>
              </div>

              <div>
                <a>Phone:</a>
                <a href="tel:+380934739258">+38 (093) 473 92 58</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
