import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import PortfolioBlock from './PortfolioBlock';
import { nanoid } from 'nanoid';

import { useSelector, useDispatch } from 'react-redux';
import { setArray, setValue } from '../../store/slice/PortfolioSlice';

import data from '../../data/tree';

import { scrollToLocation } from '../../helper';

const Portfolio = () => {
  let { name } = useParams();
  const { hash, pathname } = useLocation();
  const dispatch = useDispatch();

  const [load, setLoad] = React.useState(false);
  const { value } = useSelector((state) => state.portfolio);

  React.useEffect(() => {
    scrollToLocation();
  }, [hash, pathname]);

  React.useEffect(() => {
    dispatch(setArray(data));
  }, []);

  React.useEffect(() => {
    dispatch(name && setValue(name));
    setLoad(true);
  }, [name]);

  React.useEffect(() => {
    document.title = value?.title + ' ' + document.title;
  }, [value]);

  return (
    <main className="main page page_blog-list">
      <div className="blog-list">
        <div className="container">
          <h2 className="title-block title-block_center">{value.title}</h2>
          {load &&
            value.portfolio.map((e) => {
              return <PortfolioBlock key={nanoid()} folder={value.url} value={e} />;
            })}
        </div>
      </div>
    </main>
  );
};

export default Portfolio;
