export default [
  {
    name: 'CAD-Design',
    styleBlock: 'customer-box_create',
    icon: 'pen-icon.svg',
    url: 'cad-design',
    hash: 'cad',

    tabs: [
      {
        title: 'Crowns&Bridges',
        img: 'crowns.webp',
        description:
          'All types of frameworks and full -contour restorations like PFM, Zirconia, E.max.',
        link: 'crowns',
      },

      {
        title: 'Abutments',
        img: 'abutments.webp',
        description:
          ' All solution for You: from hybrid zirconia abutments to titanium for Pre-Face blanks.Predictable and strong',
        link: 'abutments',
      },

      {
        title: 'Bars',
        img: 'bars.webp',
        description:
          ' Predictible design for bars in All on 4, all on 6 concepts, bars for removable dentures with CEKA, Bredent and another attachmens',
        link: 'bars',
      },

      {
        title: 'Splints and Deprogramators',
        img: 'splints.webp',
        description: 'Sved-type splint, Flat plane splints, Repositioning Splint, Gelb Splint.',
        link: 'splints',
      },
    ],
  },
  {
    name: 'Surgical Guides',
    styleBlock: 'customer-box_train customer-box_green',
    icon: 'pen-icon_2.svg',
    url: 'surgical',
    hash: 'surgical',
    tabs: [
      {
        title: 'Mono Guide',
        img: 'guides.webp',
        description: `We provide planing of surgical guides based on
                                        patient's teeth. For pilot drilling and full drilling with implant placement
                                        through the surgical guide. For most than 50 implant systems`,
        link: 'mono',
      },

      {
        title: 'Double Guide',
        img: 'guides2.webp',
        description: `We provide planing for stackable guides, which
                                        include cortical pins, bone reduction, implant placement and temporary crowns
                                        based on one guide.`,
        link: 'double',
      },
    ],
  },
  {
    name: 'Orthodontics',
    styleBlock: 'customer-box_analyze customer-box_purple',
    icon: 'chart-bar-icon.svg',
    url: 'orthodontics',
    hash: 'orthodontics',
    tabs: [
      {
        title: 'Clear Aligners',
        img: 'clear_aligners.webp',
        description: `Designed by orthodontists, OUTCAD Aligners gives you
                                        complete control of your Aligners workflow. Package offer for Your practice`,
        link: 'aligners',
      },

      {
        title: 'Indirect Bonding Trays for Bracket Placement',
        img: 'indirect.webp',
        description: `Create for reducing chair time and increase patient
                                        comfort.`,
        link: 'bracket',
      },
    ],
  },
  {
    name: 'CAM-Assistant',
    styleBlock: 'customer-box_improve customer-box_blue',
    icon: 'chart-line-icon.svg',
    url: 'cam-assistant',
    hash: 'assistant',

    tabs: [
      {
        title: 'Software: Hyperdent and Millbox',
        img: 'software.webp',
        description: `Your remote milling machine operator. A set of
                                        strategies developed by our engineers will bring your product to a qualitatively
                                        new level`,
      },
    ],
  },
];
