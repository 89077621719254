import React from 'react';

import CircleGr from '../../assets/images/circle-gradient.svg';

const Slider = () => {
  return (
    <section className="opening">
      <div className="container">
        <div className="opening__inner">
          <div className="opening__info">
            <h1 className="opening__title">Solution for Your Practice in Dental Design</h1>
            <div className="subtitle-block">Best Specialists in One Place.</div>
          </div>
          <div className="opening-photo">
            <div className="opening-photo__notebook">
              <img src="./images/macbook.webp" alt=""></img>
            </div>
            <div className="opening-photo__circle">
              <img src={CircleGr} alt=""></img>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Slider;
