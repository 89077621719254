import React from 'react';

import img1 from '../../assets/images/contact/EMail.svg';
import { nanoid } from 'nanoid';

const ContactData = [
  {
    title: 'E-mail',
    desc: 'outcad24.7@gmail.com',
    icon: img1,
    url: 'mailto:outcad24.7@gmail.com',
  },
  {
    title: 'Phone',
    desc: '+38 (093) 47 392 58',
    icon: img1,
    url: 'tel:+380934739258',
  },
  {
    title: 'WhatsApp',
    desc: '+38 (093) 47 392 58',
    icon: img1,
    url: 'https://wa.me/380934739258',
  },
  {
    title: 'Viber',
    desc: '+38 (093) 47 392 58',
    icon: img1,
    url: 'viber://chat?number=%2B380934739258',
  },
  {
    title: 'FB Messenger',
    desc: '/outcad.net',
    icon: img1,
    url: 'https://m.me/107956448731025',
  },
  {
    title: 'Telegram',
    desc: '@outcad',
    icon: img1,
    url: 'https://t.me/outcad',
  },
];

const ContactRow = ({ title, desc, img, url }) => {
  const text = desc;
  return (
    <div className="contact-box">
      <div className="contact-text">
        <div className="contact-box__icon">
          <img src={img} alt="" />
        </div>
        <div className="contact-box__info">
          <div className="contact-box__title">
            <a>{title}</a>
          </div>
          <div className="contact-box__text" onClick={() => navigator.clipboard.writeText(desc)}>
            {desc}
          </div>
        </div>
      </div>
      <div className="contact-button">
        <a href={url} target="blank"></a>
      </div>
    </div>
  );
};
const OtherContact = () => {
  return (
    <section className="contact">
      <div className="container">
        <h2 className="title-block title-block_center">Quick access support</h2>
        <div className="contact__inner">
          {ContactData.map((e) => (
            <ContactRow title={e.title} desc={e.desc} img={e.icon} url={e.url} key={nanoid()} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default OtherContact;
