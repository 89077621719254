import React from 'react';
import Case from './Case';
import { nanoid } from 'nanoid';

import text from '../../data/caseTextHome';

const Cases = () => {
  return (
    <section className="customer">
      <div className="container">
        <h2 className="title-block title-block_center">Services</h2>
        <div className="subtitle-block subtitle-block_center">
          You no longer need to look for highly paid specialists, pay taxes for them and constantly
          think whether your employee will go to competitors. You are our client, so we will work
          24/7 for your success.Our experience is your advantage in the market. We are experts in
          the world of digital dentistry. More than 7 years of experience and more than 30,000
          completed cases. From an ordinary crown to full-mouth implant cases. We are with you!
        </div>
        {text.map((item) => {
          return <Case key={nanoid()} data={item} />;
        })}
      </div>
    </section>
  );
};

export default Cases;
