import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/logo-bl.svg';
import Nav from './Nav';

const Header = () => {
  return (
    <header className="header header-full">
      <div className="container">
        <div className="header__row">
          <Link to="/" className="header__logo">
            <img src={Logo} alt=""></img>
          </Link>
          <Nav />
        </div>
      </div>
    </header>
  );
};

export default Header;
