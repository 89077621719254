import React from 'react';

import Img1 from '../../assets/images/price.svg';
import Img2 from '../../assets/images/check-circle_pink.svg';
import Img3 from '../../assets/images/check-circle_purple.svg';
import Img4 from '../../assets/images/check-circle_green.svg';
const About = () => {
  return (
    <>
      <section className="opening opening-finance">
        <div className="opening-finance-box">
          <div className="container">
            <div className="opening__inner opening__inner_top">
              <div className="opening__info">
                <h1 className="opening__title" style={{ paddingTop: '100px' }}>
                  Transforming the World of Dentistry
                </h1>
                <div className="subtitle-block">
                  We will provide you with the predicted result from the scan to the finished milled
                  structure.
                  <br />
                  We are with you!
                </div>
              </div>
              <div className="opening__video">
                <div className="video-content">
                  <div className="video-content__place">
                    <img src={Img1} alt="" />
                  </div>
                  <div className="video-content__icon purple"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about">
        <div className="container">
          <div className="about__box">
            <div className="about__info">
              <div className="about__title">Our story</div>
              <div className="about__text">
                <p>
                  We are a team of dental technicians, dentists, and engineers with 7 years of
                  experience in digital dentistry. For us, every clinical situation is a challenge
                  that we will lose personally.{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="advantages-check mb-100">
        <div className="container">
          <h2 className="title-block title-block_center">Our mission</h2>
          <div className="subtitle-block subtitle-block_center">
            Our mission is to close the need for qualified staff where there is an acute need and to
            make you as a business independent of the human factor of the owner.
          </div>
          <div className="advantages-check__inner">
            <div className="advantages-box">
              <div className="advantages-box__icon-check">
                <img src={Img2} alt="" />
              </div>
              <div className="advantages-box__info">
                <div className="advantages-box__title">
                  As of today, we offer solutions on many platforms, namely
                </div>
                <ul className="advantages-box__list">
                  <li>Exocad</li>
                  <li>3shape</li>
                  <li>RealGuide</li>
                  <li>HyperDent</li>
                  <li>Millbox</li>
                </ul>
              </div>
            </div>
            <div className="advantages-box">
              <div className="advantages-box__icon-check">
                <img src={Img3} alt="" />
              </div>
              <div className="advantages-box__info">
                <div className="advantages-box__title">
                  More than 5 years of experience with intraoral scanners:
                </div>
                <ul className="advantages-box__list">
                  <li>Sirona</li>
                  <li>3shape</li>
                  <li>Medit</li>
                  <li>iTero</li>
                </ul>
              </div>
            </div>
            <div className="advantages-box">
              <div className="advantages-box__icon-check">
                <img src={Img4} alt="" />
              </div>
              <div className="advantages-box__info">
                <div className="advantages-box__title">
                  Also our experience with 3D printers and milling machines
                </div>
                <ul className="advantages-box__list">
                  <li>Roland</li>
                  <li>Imes Icore</li>
                  <li>Amann Girrbach</li>
                  <li>XtCera</li>
                  <li>YenaDent</li>
                  <li>Aidite</li>
                  <li>ZirkonZahn</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
