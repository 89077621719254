export default [
  {
    id: 1,
    title: 'CAD-Design',
    name: 'cad',
    service: [
      {
        title: 'Frameworks, Crowns, Bridges',
        price: 7,
        currency: 'USD',
        symbol: '$',
        description:
          'All non-implant types of cases. You can produce it from E.max, Zirconia, Cobalt-Chrome',
      },

      {
        title: 'Wax-up',
        price: 7,
        currency: 'USD',
        symbol: '$',
        description: '+ creating model for 3D printing.',
      },
      {
        title: 'Frameworks, Crowns, Bridges on implants',
        price: 10,
        currency: 'USD',
        symbol: '$',
        description: 'All implant cases direct for implant level or from Ti-Base level',
      },
      {
        title: 'Bar (all on 4, all on 6)',
        price: 50,
        currency: 'USD',
        symbol: '$',
        description: 'From multiunit and implant level',
      },
      {
        title: 'Veneers, Onlays, Ovelays',
        price: 7,
        currency: 'USD',
        symbol: '$',
        description: '',
      },
      {
        title: 'Primary, Secondary Telescopes',
        price: 10,
        currency: 'USD',
        symbol: '$',
        description: '',
      },
      {
        title: 'Custom zirconia and titanium abutments',
        price: 10,
        currency: 'USD',
        symbol: '$',
        description: '',
      },
      {
        title: 'Impressions Trays',
        price: 7,
        currency: 'USD',
        symbol: '$',
        description: '',
      },
    ],
  },
  {
    id: 2,
    title: 'Surgical Guides',
    name: 'surgical',
    service: [
      {
        title: 'Surgical guides',
        price: 7,
        priceEach: 15,
        textPrice: '{s}{p} + {s}{p2} for each implant',
        currency: 'USD',
        symbol: '$',
        description: '',
      },

      {
        title: 'Surgical guides Stackable',
        price: 200,
        textPrice: 'Plus {s}{p}',
        currency: 'USD',
        symbol: '$',
        description: '',
      },
    ],
  },
  {
    id: 3,
    title: 'Orthodontics',
    name: 'orthodontics',
    service: [
      {
        title: 'Clear Aligners',
        price: 200,
        currency: 'USD',
        symbol: '$',
        description: '',
        per: 'Case',
      },

      {
        title: 'Indirect bonding bracket',
        price: 100,
        currency: 'USD',
        symbol: '$',
        description: '',
      },
    ],
  },

  {
    id: 4,
    title: 'CAM-Assistant',
    name: 'assistant',
    service: [
      {
        title: 'CAM Assistant 1 Milling Unit',
        price: 300,
        currency: 'USD',
        symbol: '$',
        description: '',
        per: 'months',
      },

      {
        title: 'CAM Assistant 2 Milling Units',
        price: 500,
        currency: 'USD',
        symbol: '$',
        description: '',
        per: 'months',
      },

      {
        title: 'CAM Assistant 3 Milling Units',
        price: 700,
        currency: 'USD',
        symbol: '$',
        description: '',
        per: 'months',
      },

      {
        title: 'CAM Assistant 4-5 Milling Units',
        price: 1000,
        currency: 'USD',
        symbol: '$',
        description: '',
        per: 'months',
      },
    ],
  },
];
