import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Burger from './Burger';
import Footer from './Footer';
import Header from './Header';

const Layout = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scroll({ behavior: 'smooth', top: -3000 });
  }, [pathname]);

  return (
    <div className="wrapper wrapper-wh">
      <Header />
      <Outlet />
      <Burger />
      <Footer />
    </div>
  );
};

export default Layout;
