import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import './assets/css/style.css';
import 'yet-another-react-lightbox/styles.css';
import './assets/css/mobile.css';

import { Provider } from 'react-redux';
import { store } from './store/store';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
